@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

:root {
  --thead-bg-color: #58A46B;
  --nav-width: 150px;
  --bs-link-color: #58A46B;
}
.modal {
  --bs-modal-zindex: 1155;
}
.btn-primary {
  --bs-btn-bg: #58A46B;
  --bs-btn-border-color:#58A46B;

  --bs-btn-hover-bg: #48945B;
  --bs-btn-hover-border-color: #48945B;
  --bs-btn-active-bg: #48945B;
  --bs-btn-active-border-color: #48945B;

  --bs-btn-disabled-bg: gray;
  --bs-btn-disabled-border-color: gray;
}

.temple-signup-container, .temple-signup-modal .btn-primary, .temple-signup-confirmation .btn-primary, .guidelines-confirmation .btn-primary {
  --thead-bg-color: #6D301B;
  --bs-link-color: #6D301B;
  --bs-btn-bg: #6D301B;
  --bs-btn-border-color:#6D301B;

  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: #5D200B;
  --bs-btn-active-bg: #5D200B;
  --bs-btn-active-border-color: #5D200B;

  --bs-btn-disabled-bg: gray;
  --bs-btn-disabled-border-color: gray;
}

.temple-signup-modal .btn-primary, .temple-signup-confirmation .btn-primary, .guidelines-confirmation .btn-primary {
  --bs-btn-hover-bg: #5D200B;
}

.guidelines-confirmation .modal-footer {
  border-top: none !important;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  margin: 0px;
  padding: 0;
  overflow: auto;
}

h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

.form-input {
  height: 38px;
  border-radius: 5px;
  border: 1px solid hsl(0, 0%, 80%);
  padding-left: 15px;
}

.form-label {
  margin-bottom: 0px;
}

.modal-title.h4 {
  font-size: 20px;
}

p.align-center {
  text-align: center;
}

.app-container {
  display: flex;
  min-height: calc(100vh);
}

.page-container {
  padding: 70px 40px 40px 40px;
  width: 100%;
  position: relative;
  background-color: white;
}

a.logo {
  max-width: 135px;
  padding: 0 10px;
  margin-bottom: 20px;
}

a.logo img {
  width: 100%;
}

.spinner-container {
  display: inline-flex;
  align-items: center;
  font-style: italic;
  vertical-align: bottom;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }  
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 24px;
  height: 24px;
  border: 5px solid #A3A3A3; /* Light grey */
  border-top: 5px solid var(--bs-link-color);
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  margin: 5px 5px;
}

.btn .loading-spinner, a .loading-spinner {
  margin: 0 0;
}

.btn:disabled .loading-spinner {
  border-top: 5px solid black;
}

.btn.btn-primary>svg {
  margin-top: -2px;
  margin-right: 5px;
}

.page-toolbar {
  display: flex;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 6;
  background-color: #F2F2F2;
  width: calc(100% - 15px);
  padding-right: 5px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
  gap: 10px;
  transform: translate3d(0px, 0px, 0px); 
}

.page-filters {
  display: flex;
  align-items: center;
  gap: 10px;
  position: fixed;
  width: calc(100% - 15px);
  left: 0px;
  padding-left: 30px;
  z-index: 5;
  top: 72px;
  padding: 20px 15px 10px 45px;
  background-color: white;
  transform: translate3d(0px, 0px, 0px); 
}

.filter-group {
  max-width: 50px;
  line-height: 1.2;
  font-size: 13px;
  font-weight: 500;
}

.page-filters .calendar-period {
  margin-left: auto;
}

.table-filters {
  display: flex;
  align-items: center;
  gap: 10px;
}

.table-filters .filter-group {
  max-width: 60px;
  margin-right: 10px;
}

.table-filters .react-datepicker__input-container input[type=text] {
  font-size: 14px;
}

/* .bookings-page .page-toolbar .search-field {
  flex: 1;
}

.bookings-page .page-toolbar .search-field input {
  width: 100%;
  font-style: italic;
} */


.page-toolbar .action-buttons {
  /* flex: 1; */
}

.page-toolbar .type-filter {
  min-width: 150px;
  margin-right: 20px;
}

.page-toolbar .date-pickers {
  align-self: center;
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: -7px;
}

.page-toolbar .date-pickers>div>div input[type=text] {
  margin-right: 10px;
  margin-left: 5px;
}

.react-datepicker__input-container input[type=text] {
  min-height: 38px;
  border-radius: 5px;
  border: 1px solid hsl(0, 0%, 80%);
  padding-left: 10px;
  width: 115px;
}

.form-field-info {
  font-size: 14px;
  font-style: italic;
  color: grey;
}

form>div {
  margin-bottom: 20px;
}

form.was-validated div.invalid div[class$="-control"] {
  border-color: #dc3545;
}
form.was-validated div.invalid::after  {
  content: ' ';
  padding-right: calc(1.5em + 0.75rem);
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  width: calc(0.75em + 0.375rem);
  height: calc(0.75em + 0.375rem);
  top: 10px;
  right: 35px;
  position: absolute;
}

form.was-validated div.valid::after {
  content: ' ';
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  width: calc(0.75em + 0.375rem);
  height: calc(0.75em + 0.375rem);
  top: 10px;
  right: 35px;
  position: absolute;
}

form label.required {
  position: relative;
}

form label.required::after {
  content: '*';
  color: red;
  position: absolute;
  right: -10px;
  top: 0px;
}

form.was-validated .form-check-input:valid {
  border-color: rgba(0,0,0,.25);
}
form.was-validated .form-check-input:valid~.form-check-label {
  color: black;
}

form .react-datepicker__input-container input[type=text] {
  width: 100%;
}

p.error {
  color: red;
}

li.nav-item button:hover {
  color: #48945B;
}

.period-filter {
  display: inline-flex;
  margin-left: 20px;
  color: #58A46B;
  align-items: center;
}
.period-filter>span {
  margin-top: 3px;
}

.table-filters .assignee-filter {
  min-width:160px;
}

.table-filters .type-filter {
  min-width:120px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  left: 5px;
  top: 15px;  
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  overflow: hidden !important;
  /* padding: 2.5em 1.5em 0; */
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


.bm-item-list {
  list-style-type: none;
  padding: 40px 0px 0px 0px;
  width: 100%;
}

.bm-item-list a {
  
  color: white !important;
  cursor: pointer;
  display: block;
  text-decoration: none;
}

.bm-item-list a.l1 {
  padding: 7px 20px;
}

.bm-item-list a.l2 {
  padding: 5px 20px 5px 40px;
  font-size: 15px;
}

.bm-item-list a:not(.logo):not(.active):hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.bm-item-list a.active {
  background-color: #58A46B;
}

.login-app {
  background-color: #F2F2F2;
}

.login-page {
  background-color: #F2F2F2;
  display: flex;
  background: radial-gradient(circle at center, #F2F2F2 0%, #ffdbb7 30%);
  justify-content: center;
  align-items: center;
}

.login-box {
  width: 240px;
  height: 220px;
  /* //margin: calc(25vh) auto 0 auto; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 11px 2px rgb(0, 0, 0, 0.1);
}

.login-box>img {
  width: 200px;
}

a.social-sign-in.google {
  background: url(https://shop.mooji.org/wp-content/themes/shopmoojiorg/img/svg/google-logo.svg) no-repeat;
  background-size: 25px 25px;
  background-position: 10px 12px;
  width: 200px;
  height: 51px;
  margin: 20px 20px 0px 20px;
  display: inline-block;
  color: rgb(66, 82, 110);
  background-color: white !important;
  vertical-align: middle;
  border-radius: 11px;
  border: 1px solid #DED7D3;  
}

a.social-sign-in.google:hover {
  background-color: #FBFBFB !important;
}

a.social-sign-in span {
    line-height: 51px;
    vertical-align: top;
    width: 100%;
    padding-left: 20px;
    text-align: center;
    display: inline-block;
}

.page-header {
  width: 100%;
  background-color: #F2F2F2;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 50px;
  display: flex;
  z-index: 5;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
}

body.menu .page-header {
  padding-left: calc(var(--nav-width) + 40px);
}

.page-header h1 {
  margin-bottom: 0px;
}

table.data-table {
  margin-top: 20px;
}
table.data-table thead tr {
  background-color: var(--thead-bg-color);
  color: white;
}

table.data-table td, table.data-table th {
  line-height: 1.4;
}

table.data-table .row-actions button {
  margin-right: 10px;
}

.shifts-page .data-table-container {
  max-width: 1200px;
}

.shifts-table td.shift-time {
  min-width: 100px;
}
.shifts-table td.shift-type {
  min-width: 80px;
}

.signups-page .data-table-container {
  min-width: 500px;
  max-width: 800px;
}

.rota-page .signups-table tr.today {
  background-color: #FFFFE0;
}
.rota-page .signups-table tr.today>* {
  --bs-table-accent-bg: #FFFFE0 !important;
}

.rota-page .signups-table tr td:nth-child(4) {
  min-width: 190px;
}

.types-table-container {
  max-width: 800px;
}

.periods-table-container {
  max-width: 800px;
}
/* .shifts-page {
  overflow: scroll;
} */

.laundry-signup-container {
  max-width: 1000px;
}
.temple-signup-container {
  max-width: 800px;
}
.laundry-signup-container .page-header,
.temple-signup-container .page-header {
  padding-left: 20px !important;
}
.laundry-signup-page.page-container,
.temple-signup-page.page-container {
  width: 100% !important;
  transform: none !important;
  padding-left: 20px !important;
}

@media (min-width: 769px) {
  body.menu .page-container:not(.login-page) {
    width: calc(100% - var(--nav-width) + 10px);
    transform: translate3d(var(--nav-width), 0, 0); transition: all 0.5s ease 0s; /* for Safari */
  }
  body.menu .page-toolbar,
  body.menu .page-filters {
    padding-left: 20px;
    width: calc(100% - var(--nav-width));
    transform: translate3d(var(--nav-width), 0px, 0px); 
  }
  body.menu .bm-menu-wrap {
    display: block !important; /* for Safari */
  }
  .bm-cross-button {
    display: none;
  }
}

@media (max-width: 768px) {
  .bm-burger-button {
    top: 15px;
    left: 20px;
  }
  .bookings-app .bm-burger-button {
    top: 27px;
    left: 10px;
  }
  .page-container {
    padding: 70px 20px 40px 20px;
  }
  .page-toolbar {
    flex-wrap: wrap;
    width: 100%;
    padding-right: 10px;
    padding-left: 50px;
  } 
  .page-toolbar .date-pickers {
    flex: 1 1 0px;
  }

  .page-toolbar .date-pickers>div>div input[type=text] {
    margin-right: 0px;
  }

  .page-filters {
    flex-wrap: wrap;
    width: 100%;
    top: 110px;
    padding-left: 20px;
  }

  .page-header {
    padding-left: 70px;
  }

  body.menu .page-header {
    padding-left: 0px;
  }

  .signup-page.page-container {
    padding-left: 10px;
    padding-right: 0px;
  }
  .rota-page.page-container, .extra-support-page.page-container {
    padding-right: 0px;
  }
}
